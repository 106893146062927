.team-section {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* padding: 20px; */
   }
  
  /* .taleido_heading {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }
   */
  .team-members {
    display: flex;
    justify-content: center;
    flex-wrap: wrap; 
    gap: 20px;
    height: 300px;
  }
  
  .team-member {
    text-align: center;
    max-width: 250px;
  }
  
  .team-image {
    width: 100%;
    height: 60%; 
    border-radius: 50%;
    object-fit: cover;
    border: 2px solid #ddd;
    display: block;
    margin: 0 auto;
  }
  
  
  .team-name {
    font-size: 18px;
    font-weight: bold;
    color: #5f69d2;
    margin-top: 10px;
  }
  
  .team-role {
    font-size: 16px;
    color: #28a745;
    font-weight: bold;
  }
  